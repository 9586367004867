import React, { useEffect } from 'react'
import {
  usePlaidLink,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkError,
  PlaidLinkOptionsWithLinkToken,
  PlaidLinkOnEventMetadata,
  PlaidLinkStableEvent,
} from 'react-plaid-link'

import { debugPrint, logAnalyticsEvent } from '../utils/helpers'
import { useLink, useErrors } from '../services'
import { exchangeToken, updateItemStatus } from '../services/firebase'

interface Props {
  isOauth?: boolean
  token: string
  userId: string
  itemId?: string | null
  children?: React.ReactNode
}

export default function LaunchLink(props: Props) {
  const { generateLinkToken, deleteLinkToken } = useLink()
  const { setError, resetError } = useErrors()

  // define onSuccess, onExit and onEvent functions as configs for Plaid Link creation
  const onSuccess = async (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    debugPrint("[LaunchLink onSuccess] :: metadata: " + JSON.stringify(metadata) + "userID: " + props.userId + "itemID: " + props.itemId)
    if (props.itemId != null) {           // update mode: no need to exchange public token
      await updateItemStatus(props.itemId, null)
      deleteLinkToken(null, props.itemId)
    } else {                              // regular link mode: exchange public token for access token
      try {
        await exchangeToken(
          publicToken,
          metadata.institution,
          metadata.accounts,
          props.userId
        )
      } catch (error) {
        logAnalyticsEvent({ type: 'plaid-link-token-error', message: `LaunchLink onSuccess got error: ${JSON.stringify(error)}` })
        setError('internal', "An error occurred while trying to connect your account. Please contact support if the issue persists.")
      }
    }
    resetError()
    deleteLinkToken(props.userId, null)
  }

  const onExit = async (
    error: PlaidLinkError | null,
    metadata: PlaidLinkOnExitMetadata
  ) => {
    if (error !== null && error.error_code === 'INVALID_LINK_TOKEN')
      await generateLinkToken(props.userId, props.itemId)
    
    if (error != null) {
      logAnalyticsEvent({ type: 'plaid-link-exit-early', message: `LaunchLink onExit got error: ${JSON.stringify(error)}` })
      setError(error.error_code, error.display_message || error.error_message)
      // to handle other error codes, see https://plaid.com/docs/errors/
    }

    debugPrint("[LaunchLink onExit] :: error: " + JSON.stringify(error) + " metadata: " + JSON.stringify(metadata) + " userID: " + props.userId, 'info')
  }

  const onEvent = async (
    eventName: PlaidLinkStableEvent | string,
    metadata: PlaidLinkOnEventMetadata
  ) => {
    // handle errors in the event end-user does not exit with onExit function error enabled.
    if (eventName === 'ERROR' && metadata.error_code != null) {
      logAnalyticsEvent({ type: 'plaid-error', message: `LaunchLink onEvent got error: ${JSON.stringify(metadata)}` })
      setError(metadata.error_code, ' ')
    }

    debugPrint(`- onEvent eventName: ${eventName}`)
    debugPrint(`- onEvent metadata: ${JSON.stringify(metadata, null, '\t')}`)
  }

  const config: PlaidLinkOptionsWithLinkToken = {
    onSuccess,
    onExit,
    onEvent,
    token: props.token,
  }

  if (props.isOauth) {
    config.receivedRedirectUri = window.location.href // add additional receivedRedirectUri config when handling an OAuth reidrect
    debugPrint(`LaunchLink receivedRedirectUri: ${config.receivedRedirectUri}`, 'info')
  }

  const { open, ready } = usePlaidLink(config)

  useEffect(() => {
    if (props.isOauth && ready) {
      open()
    } else if (ready) {
      // set link token, userId and itemId in local storage for use if needed later by OAuth
      localStorage.setItem(
        'oauthConfig',
        JSON.stringify({
          userId: props.userId,
          itemId: props.itemId,
          token: props.token,
        })
      )
      open()
    }
  }, [ready, open, props.isOauth, props.userId, props.itemId, props.token])

  return <></>
}
